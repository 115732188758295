<template>
  <div>
    <div>
      <div class="row">
        <div class="col-12">
          <div class="card card-custom gutter-b example example-compact">
            <div
              class="
                card-header
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <div class="card-title">
                <h3 class="card-label">
                  Kunlik amortizatsiya va balans xisob raqami provodkasi
                  bog'liqligi
                </h3>
              </div>
              <div>
                <v-btn @click="changed">check</v-btn>
                <v-switch
                  @change="switchChaneg(switch1)"
                  v-model="switch1"
                  :label="switch1 ? `Ulanmagan` : 'Ulangan'"
                ></v-switch>
              </div>
            </div>
            <div class="card-body">
              <div class="d-flex">
                <div class="col-3">
                  <v-text-field
                    dense
                    outlined
                    disabled
                    :value="getData.total"
                    label="Total"
                  ></v-text-field>
                </div>
                <div class="col-3">
                  <v-text-field
                    dense
                    outlined
                    disabled
                    :value="getData.count"
                    label="Count"
                  ></v-text-field>
                </div>
              </div>
              <v-data-table
                :headers="headers"
                :loading="isLoading"
                disable-pagination="true"
                loading-text="...."
                :items="getData.daily_amortizations"
                hide-default-footer
                class="elevation-1"
              >
              </v-data-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  data() {
    return {
      switch1: false,
      page: 1,
      headers: [
        { text: 'id', value: 'id' },
        { text: 'oper_date', value: 'oper_date' },
        { text: 'operation_amount', value: 'operation_amount' },
        { text: 'main_assets', value: 'main_assets' },
        { text: 'bank_account_code', value: 'bank_account_code' }
      ]
    }
  },
  beforeCreate() {
    this.$store.dispatch('bankAccountLink', false)
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Moliya' },
      {
        title:
          'Kunlik amortizatsiya va balans xisob raqami provodkasi bogliqligi'
      }
      //   { title: 'Xarajat Kategoriyalari' }
    ])
  },
  methods: {
    changed() {
      this.$store.dispatch('linkWithBalansAccount')
    },
    switchChaneg(val) {
      if (val == true) {
        this.$store.dispatch('bankAccountLink', true)
      } else {
        this.$store.dispatch('bankAccountLink', false)
      }
    }
  },
  computed: {
    getData() {
      console.log('this.$store.state.requests.bankAccountLink','---------------BANK_ACCOUNT_LINK');
      return this.$store.state.requests.bankAccountLink
    }
  }
}
</script>

<style scoped>
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
</style>
